import React from "react"
import { useStaticQuery, graphql, Link as RawLink } from "gatsby"
import Img from "gatsby-image"

import P from "../typography/p"
import H3 from "../typography/h3"
import Link from "../typography/link"

const Post = ({
  poster,
  node: {
    frontmatter: { title, description, path },
  },
}) => {
  return (
    <li className="flex flex-col md:flex-row">
      <Link to={`/${path}`}>
        <Img className="max-w-full mt-4 rounded-lg shadow-2xl" fixed={poster} />
      </Link>
      <div className="flex-grow-0 md:w-1/2">
        <H3 className="md:pl-4">
          <RawLink to={`/${path}`}>{title}</RawLink>
        </H3>
        <P className="md:pl-4">{description}</P>
        <Link className="block pt-2 md:pl-4" to={`/${path}`}>
          Read the article
        </Link>
      </div>
    </li>
  )
}

const buildImageDict = images => {
  return images.reduce((dict, { node }) => {
    if (node.childImageSharp)
      dict[node.relativePath] = node.childImageSharp.fixed
    return dict
  }, {})
}

const PostsList = () => {
  const {
    allMarkdownRemark: { edges: posts },
    allFile: { images },
  } = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        sort: { fields: frontmatter___date, order: DESC }
        filter: { fileAbsolutePath: { regex: "/blog/" } }
      ) {
        edges {
          node {
            frontmatter {
              title
              poster
              description
              path
            }
          }
        }
      }
      allFile(filter: { extension: { regex: "/(png)|(jpg)/" } }) {
        images: edges {
          node {
            childImageSharp {
              fixed(width: 400, height: 225) {
                ...GatsbyImageSharpFixed
              }
            }
            relativePath
          }
        }
      }
    }
  `)

  const imageDict = buildImageDict(images)

  return (
    <ol>
      {posts.map(post => (
        <Post poster={imageDict[post.node.frontmatter.poster]} {...post} />
      ))}
    </ol>
  )
}

export default PostsList
