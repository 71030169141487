import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/blog/hero"
import PostsList from "../components/blog/postlist"

import H2 from "../components/typography/h2"
import H3 from "../components/typography/h3"
import P from "../components/typography/p"

const Posts = () => {
  return (
    <Layout>
      <SEO title="Blog" />
      <Hero />
      <div style={{ background: "#EBEDEB" }} className="px-4 pb-8">
        <div className="max-w-4xl mx-auto">
          <H2>The noima blog</H2>
          <H3>An exploration of software, data and everything in between</H3>
          <P>
            We believe that software and data are extremely complex; In order to
            build on them you need a sustainable environment. This blog humbly
            gives our point of view on the different topics we think are
            important from software to data. 
          </P>
          <H2>Latest posts</H2>
          <PostsList />
        </div>
      </div>
    </Layout>
  )
}

export default Posts
